<template>
  <div class="homeView">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in banner" :key="item.id" @click="swipeClick(item)"><img :src="item.picUrl">
      </van-swipe-item>
    </van-swipe>
    <!-- 圈子部分 -->
    <div class="title-container">
      <h3 class="titleName">圈子</h3>
      <div class="moreBtn" @click="moreCircle">更多
        <van-icon name="arrow"/>
      </div>
    </div>
    <div class="circle-card-content">
      <circle-card v-for="item in userCommunityList" :key="item.id" :itemData="item"
                   @click.native="goCircleDetails(item.id,item.picUrl)"/>
    </div>
    <div class="line"></div>
    <!-- 问卷 -->
    <div class="title-container active">
      <h3 class="titleName">问卷</h3>
      <div class="moreBtn" @click="moreQuestionnaire">更多
        <van-icon name="arrow"/>
      </div>
    </div>
    <template v-if="activity.length!==0">
      <questionnaire-card v-for="item in activity" :key="item.id" @click.native="goOutLink(item)"
                          :itemData="item"/>
    </template>
    <van-empty v-if="activity.length===0" description="暂无问卷数据"/>
  </div>
</template>

<script>
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'
  import circleCard from './components/circleCard'
  import questionnaireCard from './components/questionnaireCard'
  import {Toast} from "vant";

  export default {
    name: "homeView",
    components: {
      circleCard,
      questionnaireCard
    },
    data() {
      return {
        banner: [], // 轮播图列表
        userCommunityList: [], //圈子列表
        activity: [], //活动列表
      };
    },
    created() {

    },
    async mounted() {
      if (!localStorage.sessionKey && untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      } else {
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (!UserInfo.phone || UserInfo.phone === null || UserInfo.phone === '') {
          if (untils.isWechat()) {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }
      }

      this.getBannerList()
    },
    methods: {
      getBannerList() {
        this.$api.home.homepPageList({}, res => {
          if (res.code === 200) {
            this.banner = res.data.banner
            this.userCommunityList = res.data.userCommunityList
            this.activity = res.data.activity
          } else {
            this.$toast(res.msg)
          }
        })
      },
      moreCircle() {
        this.$router.push('/home/circle')
      },
      moreQuestionnaire() {
        this.$router.push('/home/questionnaire')
      },
      goOutLink(item) {
        if (item.status == 0){
          Toast("该问卷暂未开始，请耐心等待")
        }else if (item.status == 1){
          switch (item.sampleStatus) {
            case 0:
              Toast("审核中，请耐心等待")
              break
            case 1:
              Toast("审核已通过")
              break
            case 2:
              Toast("审核已驳回")
              break
            default:
              window.open(item.accessLinks)
              break
          }
        }else {
          Toast("该调查已结束")
        }
      },
      goCircleDetails(id, picUrl) {
        this.$router.push('/circleDetails?id=' + id + '&picUrl=' + picUrl)
      },
      swipeClick(item) {
        if (item.accessLinks) {
          this.goOutLink(item.accessLinks)
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .homeView {
    padding: 28px 30px 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
  }

  .my-swipe {
    width: 100%;
    height: 240px;
    border-radius: 10px;
    overflow: hidden;

    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /deep/ .van-swipe__indicator {
      width: 16px;
      height: 4px;
      border-radius: 0;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 38px 0 20px 0;

    &.active {
      margin: 40px 0 28px 0;
    }

    .titleName {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 44px;
    }

    .moreBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #666666;
      width: 114px;
      height: 48px;
      border-radius: 24px;
      border: 2px solid #E3E3E3;

    }
  }

  .circle-card-content {
    width: 100%;
    display: flex;
    padding-bottom: 30px;

    & > div {
      margin-right: 22px;
    }

    & > div:last-child {
      margin-right: 0;
    }
  }

  .line {
    width: 100%;
    height: 20px;
    background-color: #F6F7F9;
    position: absolute;
    left: 0;
  }
</style>
